.scapii {
  background: #fff;
  padding: 40px 0 20px 0;
  /* color: #fff; */
}

.scapii .counters h1 {
  font-size: 26px;
  display: block;
  font-weight: 700;
}
.scapii span {
  color: #b82556;
  font-size: 38px;
}

.scapii .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
