.partners {
  padding: 12px 0;
  text-align: center;
}
.partners input {
  width: 100%;
  margin-bottom: 10px;
  padding: 4px;
  max-width: none;

  border-radius: 5px;
  border: solid 1px #e3e3e3;
  text-align: center;
}
@media (min-width: 992px) {
  .partners input {
    max-width: 50vw;
  }
}
.partners img {
  max-width: 70%;
  margin: -15px;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  object-fit: contain;
  filter: grayscale(100);
}

.partners img:hover {
  filter: none;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .partners img {
    max-width: 40%;
  }
}
