#hero {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
@media (max-height: 500px) {
  #hero {
    height: 150vh;
  }
}
#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
#hero .carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#hero .carousel-item::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.666);
}
#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 50px;
  right: 50px;
}
#hero .container {
  text-align: center;
}
#hero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 768px) {
  #hero h2 {
    font-size: 20px;
    line-height: 1.2;
  }
}
#hero p {
  width: 80%;
  font-size: 38px;
  line-height: 1.2;
  margin: 0 auto 30px auto;
  color: #fff;
}
@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
}
@media (max-width: 768px) {
  #hero p {
    font-size: 28px;
    line-height: 1.2;
  }
}
#hero .carousel-fade {
  overflow: hidden;
}
#hero .carousel-fade .carousel-inner .carousel-item {
  transition-property: opacity;
}
#hero .carousel-fade .carousel-inner .carousel-item,
#hero .carousel-fade .carousel-inner .active.carousel-item-start,
#hero .carousel-fade .carousel-inner .active.carousel-item-end {
  opacity: 0;
}
#hero .carousel-fade .carousel-inner .active,
#hero .carousel-fade .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-fade .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}
#hero .carousel-fade .carousel-inner .carousel-item-next,
#hero .carousel-fade .carousel-inner .carousel-item-prev,
#hero .carousel-fade .carousel-inner .active.carousel-item-start,
#hero .carousel-fade .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}
#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}
@media (min-width: 1024px) {
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 32px;
  line-height: 1;
}
#hero .carousel-indicators li {
  cursor: pointer;
}
#hero .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #f05d08d5;
}
#hero .btn-get-started:hover {
  background: #fff;
  color: #f05d08d5;
}
