.footer {
  background-color: #74ac43;
  color: white;
  font-size: 14px;
  position: relative;
}

.footer .footer-top {
  padding: 50px 0;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 10px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--heading-font);
  color: var(--heading-color);
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  color: white;

  background-color: color-mix(in srgb, var(--default-color) 5%, white 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: white;

  background-color: var(--accent-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  margin-right: 4px;
  font-size: 12px;
  line-height: 0;
  color: var(--accent-color);
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: white;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .footer-newsletter .newsletter-form {
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 6px 8px;
  position: relative;
  border-radius: 4px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
  display: flex;
  background-color: #f3f3f375;
  transition: 0.3s;
}

.footer .footer-newsletter .newsletter-form:focus-within {
  border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type="email"] {
  border: 0;
  padding: 4px;
  width: 100%;
  background-color: color-mix(
    in srgb,
    var(--footer-background-color) 90%,
    white 15%
  );
  color: white;
}

.footer .footer-newsletter .newsletter-form input[type="email"]:focus-visible {
  outline: none;
}

.footer .footer-newsletter .newsletter-form input[type="submit"] {
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  margin: -7px -8px -7px 0;
  background: var(--accent-color);
  color: #e3e3e3;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}
.footer .footer-newsletter .pass-input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}

.footer .footer-newsletter .newsletter-form input[type="submit"]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.footer .copyright {
  padding: 30px 0;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 5px;
  font-size: 13px;
}
