:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway", sans-serif;
  --nav-font: "Poppins", sans-serif;
}
:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #151515; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #b82556; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #312f2f; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}
* {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #b82556;
  text-decoration: none;
}

a:hover {
  color: #e6573f;
  text-decoration: none;
}

em {
  font-size: 14px;
  color: rgb(95, 95, 95);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
}

/* sections */
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #b82556;
}
.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #b82556;
  bottom: 0;
  left: calc(50% - 20px);
}
.section-title p {
  margin-bottom: 0;
}

.edit-btn {
  border: none;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 5px 5px #b2b1b1;
  margin-left: 10px;
  transition: 0.4s;
}
.edit-btn:hover {
  box-shadow: 0px 5px 10px #5b5b5b;
}
.edit-btn .edit-btn-icon {
  color: #b82556;
}

/* Editable text area */
.editable-textarea {
  width: 100% !important; /* Use 100% of the parent container */
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 100px;
  resize: vertical; /* Allow vertical resizing */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #444444;
  background-color: transparent;
  text-align: inherit;
  display: block; /* Ensures it doesn't stretch horizontally */
}

/* Ensure the editable container has proper width */
.content-area {
  /* background-color: red; */
  width: 90vw;
}
.editable-container {
  /* background-color: green; */
  width: 100%;
  height: inherit;
}

/* Adjusted for responsive design, limits on large screens */
@media (max-width: 768px) {
  .editable-container {
    width: 95%; /* Take up more space on smaller screens */
  }
}

.save-btn,
.cancel-btn {
  background-color: #b82556;
  color: white;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 10px;
}

.save-btn:hover {
  background-color: #ff6600;
}

.cancel-btn {
  background-color: #ccc;
  color: #444;
}

.cancel-btn:hover {
  background-color: #bbb;
}

/* Edit button styles */
.edit-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.edit-btn-icon {
  color: #b82556;
  font-size: 18px;
  transition: color 0.3s ease;
}

.edit-btn:hover .edit-btn-icon {
  color: #ff6600;
}

/* Styling for the section title */
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #b82556;
}
.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #b82556;
  bottom: 0;
  left: calc(50% - 20px);
}
